import { defineStore } from 'pinia';
import { genTestUserSig } from '@/utils/generateTestUserSig';
import { ClientOptions } from '@/types/type';

const appStore = defineStore('app', {
  state: () => ({
    sdkAppId: 1400710705,
    userId: '',
    roomId: '',
    secretKey: '',
    userSig: '',
    audioDeviceId: '',
    videoDeviceId: '',
    cameraList: [],
    microphoneList: [],
    logs: [],
    isJoined: false,
    isPublished: false,
    isShared: false,
    remoteStreams: [],
    invitedRemoteStreams: [],

    /**是否有连接通畅 */
    isUnobstructed: false,
  }),
  getters: {},
  actions: {
    getInitParamsStates() {
      return !!(this.sdkAppId  && this.roomId && this.userId);
    },
    getUserSig() {
      this.userSig = this.userSig || genTestUserSig({
        sdkAppId: this.sdkAppId,
        userId: this.userId,
        secretKey: this.secretKey,
      }).userSig;
      return this.userSig;
    },
    // createShareLink(sdkAppId,userId,userSig) {
    //   // const userId = `Guest_${Math.floor(Math.random() * 1000000)}`;
    //   // const { userSig } = genTestUserSig({
    //   //   sdkAppId:this.sdkAppId,
    //   //   userId,
    //   //   secretKey: this.secretKey,
    //   // });
    //   const { origin } = window.location;
    //   const { pathname } = window.location;
    //   return `${origin}${pathname}#/invite?userSig=${userSig}&&SDKAppId=${this.sdkAppId}&&userId=${userId}&&roomId=${this.roomId}`;
    // },
    getClientOptions(isVideo?:boolean):ClientOptions {
      return {
        sdkAppId:this.sdkAppId,
        userId:this.userId,
        roomId:parseInt(this.roomId),
        userSig:this.userSig,
        secretKey:this.secretKey,
        isVideo:isVideo,
      }
    },
    addSuccessLog(str: string) {
      this.logs.push({
        type: 'success',
        content: str,
      });
    },
    addFailedLog(str: string) {
      this.logs.push({
        type: 'failed',
        content: str,
      });
    },
  },
});

export default appStore;
